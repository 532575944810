import React, { FC } from 'react'
import desktopBackgroundImage from '../../images/services/banner-autres-desktop.jpg'
import mobileBackgroundImage from '../../images/services/banner-autres-mobile.jpg'
import { Colors } from '../../colors'
import ServicePageLayout from '../../components/layouts/service-page-layout'
import { AvailableServices } from '../../available-services'
import { ServiceDetail } from '../../components/service-detail/service-detail'

export const AutreServices: FC = () => (
    <ServicePageLayout
        primaryColor={Colors.purple}
        title="Autres services offerts par nos partenaires"
        desktopBanner={desktopBackgroundImage}
        mobileBanner={mobileBackgroundImage}
        bannerText="Autres services. Profitez du savoir de nos partenaires"
        serviceBrief={`Nos partenaires peuvent vous offrir une panoplie de produits et services individuels et collectifs. Nous sommes là pour vous guider dans différents domaines et vous permettre de naviguer vers les bons services.`}
        serviceToHide={AvailableServices.autres}
    >
        <>
            <ServiceDetail color={Colors.turquoise} title="Comptabilité & impôts" />
            <ServiceDetail color={Colors.skyBlue} title="Assurance & régime de retraite collectif" />
            <ServiceDetail color={Colors.yellow} title="Avocats : Droits des affaires et droit familial" />
            <ServiceDetail color={Colors.purple} title="Assurance de dommage et commerciale" />
            <ServiceDetail color={Colors.skyBlue} title="Notaire" />
            <ServiceDetail color={Colors.turquoise} title="Assurance voyage" />
        </>
    </ServicePageLayout>
)

export default AutreServices
